import React from "react";
import { IUSPComponentResponse } from "../types/componentResponses";

interface IUniqueSellingPoints {
  usp: IUSPComponentResponse[];
}

function UniqueSellingPoints({ usp }: IUniqueSellingPoints): JSX.Element {
  return (
    <div
      id="usp-container"
      className={`relative z-[11] h-[15.125rem] bg-[#1A1D27] desktop:mt-[4.5rem] desktop:h-[8.75rem]`}
    >
      <div className="desktop:h- flex h-full flex-col items-center justify-center gap-6 desktop:flex-row">
        {usp.map((element, index) => (
          <div
            className={` ${
              index < usp.length - 1
                ? "desktop:border-r desktop:border-darkgray"
                : ""
            } flex flex-col items-center desktop:px-[70px]`}
            key={element.id}
          >
            <h6 className="mb-2 text-h6 font-medium text-white">
              {element.USP_Text_Primary}
            </h6>
            <p className="text-p font-normal text-blue-20">
              {element.USP_Text_Secondary}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UniqueSellingPoints;
