import React from "react";
import useAppContext from "@hooks/useAppContext";
import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import GradientCover from "@components/common/GradientCover";
import ConditionalWrapper from "./common/ConditionalWrapper";
import {
  IImageComponentResponse,
  IMediaComponentResponse,
} from "../types/componentResponses";
import styledParser from "@libs/parser/styled-parser";

interface IHero {
  image: string | IMediaComponentResponse | undefined | IImageComponentResponse;
  title: string;
  eyebrow?: string | null;
  isEyebrowRichText?: boolean;
}

const Hero = ({
  image,
  title,
  eyebrow,
  isEyebrowRichText,
}: IHero): JSX.Element => {
  const { isMobile } = useAppContext();

  return (
    <div
      className={`relative min-h-[22.5rem] w-full overflow-hidden rounded-3xl desktop:h-[26.25rem]`}
    >
      <div className="relative h-[22.5rem] desktop:h-full ">
        {isMobile && <GradientCover />}
        <ImageCDN
          src={ImageDefault(image)}
          alt="w"
          width={isMobile ? "360" : "1300"}
          height={isMobile ? "360" : "420"}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <ConditionalWrapper
        condition={!isMobile}
        wrapper={(children) => {
          return (
            <div className="desktop:absolute desktop:left-0 desktop:right-0 desktop:bottom-16">
              {children}
            </div>
          );
        }}
      >
        <div className="bg-black p-3 pt-0 text-center text-white desktop:plate desktop:mx-auto desktop:max-w-[45.875rem]">
          <h1
            className={`text-h1 font-semibold text-white text-center${
              eyebrow ? " mb-2" : ""
            }`}
          >
            {title}
          </h1>
          {eyebrow && (
            <div className="text-p16 text-center text-white">
              {isEyebrowRichText ? styledParser(eyebrow, true) : eyebrow}
            </div>
          )}
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export default Hero;
