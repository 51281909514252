import React, { Suspense } from "react";
import UniqueSellingPoints from "@components/UniqueSellingPoints";
import dynamic from "next/dynamic";
import { HOME_PAGE_DETAILED, TAG_PACKAGE_THEME } from "@components/home/routes";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";
import { IHomePageResponse } from "@customTypes/commonResponses";
import { IPackageThemeResponse } from "@customTypes/modelResponses";
import fetchGet from "@services/api/fetchGet";
import MainWrapper from "@components/pages/common/MainWrapper";
import HomeHero from "@modules/homePage/HomeHero";

// Dynamic imports start here
const SearchMgtHome = dynamic(
  async () => await import("@components/SearchManagement/SearchMgtHome"),
  { ssr: false }
);

const HomeExperts = dynamic(
  async () => await import("@modules/homePage/sections/HomeExperts"),
  {
    ssr: false,
  }
);
const HomePackages = dynamic(
  async () => await import("@modules/homePage/sections/HomePackages"),
  {
    ssr: false,
  }
);
const HomeDestinations = dynamic(
  async () => await import("@modules/homePage/sections/HomeDestinations"),
  {
    ssr: false,
  }
);
const HomeReviews = dynamic(
  async () => await import("@modules/homePage/sections/HomeReviews"),
  {
    ssr: false,
  }
);
const HomeAboutUs = dynamic(
  async () => await import("@modules/homePage/sections/HomeAboutUs"),
  {
    ssr: false,
  }
);
const HomeAccommodations = dynamic(
  async () => await import("@modules/homePage/sections/HomeAccommodations"),
  { ssr: false }
);
const HomeActivities = dynamic(
  async () => await import("@modules/homePage/sections/HomeActivities"),
  {
    ssr: true,
  }
);
const SectionsContainer = dynamic(
  async () => await import("@modules/homePage/SectionsContainer"),
  { ssr: false }
);
const HomePackageThemes = dynamic(
  async () => await import("@modules/homePage/sections/HomePackageThemes"),
  {
    ssr: false,
  }
);

interface IHomePage {
  homePage: IHomePageResponse;
  packageTheme: IPackageThemeResponse[];
}

export default function Home({
  homePage,
  packageTheme,
}: IHomePage): JSX.Element {
  return (
    <MainWrapper>
      <HomeHero
        title={homePage.Slogan}
        eyebrow={homePage.Eyebrow}
        image={homePage.Hero_Media?.[0]}
      />
      <SectionsContainer>
        <HomePackages
          title={homePage.Packages_Section_Headline}
          body={homePage.Packages_Section_Text}
          packages={homePage.packages}
          horizontalScroll={homePage.Packages_Horizontal_Scroll}
        />
      </SectionsContainer>
      <UniqueSellingPoints usp={homePage.Unique_Selling_Point} />
      <SectionsContainer>
        <HomePackageThemes
          title={homePage.PackageThemes_Section_Headline}
          tags={packageTheme}
        />
      </SectionsContainer>
      <Suspense fallback={null}>
        <SearchMgtHome />
        <SectionsContainer>
          <HomeExperts
            title={homePage.TCs_Section_Headline}
            text={homePage.TCs_Section_Text}
            experts={homePage.travel_consultants}
            horizontalScroll={homePage.TCs_Horizontal_Scroll}
          />
          <HomeReviews
            title={homePage.Reviews_Section_Headline}
            section={homePage.Reviews_Section_Text}
            reviews={homePage.reviews}
            horizontalScroll={homePage.Reviews_Horizontal_Scroll}
          />
          <HomeDestinations
            locations={homePage.locations}
            title={homePage.Destinations_Section_Headline}
            body={homePage.Destinations_Section_Text}
            horizontalScroll={homePage.Destinations_Horizontal_Scroll}
          />
        </SectionsContainer>
        <HomeAboutUs
          key={homePage.About_Us.id}
          title={homePage.About_Us.AboutUsHeadline}
          text={homePage.About_Us.AboutUsText}
          image={
            homePage?.About_Us?.AboutUsMedia &&
            homePage.About_Us.AboutUsMedia[0]
          }
        />

        <SectionsContainer noMarginTopDesktop>
          <HomeAccommodations
            accommodations={homePage.accommodations}
            title={homePage.Accommodations_Section_Headline}
            body={homePage.Accommodations_Section_Text}
            horizontalScroll={homePage.Accommodations_Horizontal_Scroll}
          />
          <HomeActivities
            activities={homePage.tags}
            title={homePage.Experiences_Section_Headline}
            body={homePage.Experiences_Section_Text}
            horizontalScroll={homePage.Experiences_Horizontal_Scroll}
          />
          {/* Hidden for initial Release - NAM-1687 */}
          {/* {homePage.articles && homePage.articles.length > 0 && (
                <div className="m-auto desktop:w-[82rem] desktop:max-w-[1300px]">
                  <HomeArticles
                    title={homePage.Articles_Section_Headline}
                    body={homePage.Articles_Section_Text}
                    articles={homePage.articles}
                    horizontalScroll={homePage.Articles_Horizontal_Scroll}
                  />
                </div>
              )} */}
        </SectionsContainer>
      </Suspense>
    </MainWrapper>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const homePageData = await fetchGet(HOME_PAGE_DETAILED);
  const homePage = await homePageData.json();

  const packageThemeData = await fetchGet(TAG_PACKAGE_THEME);
  const packageTheme = await packageThemeData.json();

  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls();
  const pageType = 1;
  return {
    props: {
      footer,
      topNavigation,
      mediaAssets,
      homePage,
      packageTheme,
      pageType,
      menus,
      usp,
      searchManagement: searchManagementRes,
    },
    revalidate: Number(process.env.REVALIDATE_TIMER),
  };
};
