import React from "react";

interface ISectionsContainer {
  children: JSX.Element | JSX.Element[];
  noMarginTopDesktop?: boolean;
}

const SectionsContainer = ({
  children,
  noMarginTopDesktop = false,
}: ISectionsContainer): JSX.Element => {
  return (
    <div
      className={`mx-auto mt-[2rem] ${
        noMarginTopDesktop ? "desktop:mt-[-1.5rem]" : "desktop:mt-[3.5rem]"
      } desktop:w-[81.25rem] desktop:max-w-[95%]`}
    >
      {children}
    </div>
  );
};

export default SectionsContainer;
