import React from "react";
import useAppContext from "@hooks/useAppContext";
import { IMediaComponentResponse } from "@customTypes/componentResponses";
import Hero from "@components/Hero";
import SectionsContainer from "./SectionsContainer";

interface IHomeTitle {
  title: string;
  eyebrow?: string | null;
  image?: IMediaComponentResponse;
}

const HomeTitle = ({ title, eyebrow, image }: IHomeTitle): JSX.Element => {
  const { isMobile } = useAppContext();
  return (
    <SectionsContainer>
      <div className="relative mx-[0.6rem] -mt-5 desktop:m-0 desktop:mt-[4.5rem] desktop:-mb-2">
        {!isMobile && (
          <div className="absolute left-[-27rem] bottom-[5rem] z-[-1] h-full w-full -rotate-45 scale-y-[-1] bg-waves bg-left-bottom bg-no-repeat" />
        )}
        <Hero
          title={title}
          eyebrow={eyebrow}
          image={image}
          isEyebrowRichText={true}
        />
      </div>
    </SectionsContainer>
  );
};
export default HomeTitle;
